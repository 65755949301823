import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { Controller, Navigation } from "swiper";
// eslint-disable-next-line
import { Swiper, SwiperSlide } from "swiper/react";
import HeroIcon from "components/HeroIcon";
import SlideNextButton from "components/Swiper/SlideNextButton";
import SlidePrevButton from "components/Swiper/SlidePrevButton";
import classNames from "lib/classNames";

export default function QuickAccessMenu({ menu }) {
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();
  const isNotAlias = /(?:\/node\/)/;

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <div className="relative">
      <SlidePrevButton prevRef={prevRef} theme="rounded-outline" />
      <Swiper
        modules={[Navigation, Controller]}
        breakpoints={{
          0: {
            slidesPerView: 2.5,
            centeredSlides: true,
            spaceBetween: 50,
            initialSlide: 1,
          },
          638: {
            slidesPerView: 4,
            centeredSlides: false,
          },
          1280: {
            slidesPerView: 5,
            centeredSlides: false,
          },
        }}
        navigation={{
          prevEl: prevRef?.current,
          nextEl: nextRef?.current,
        }}
        spaceBetween={50}
        slidesPerView={5}
        onSwiper={setSwiper}
        className="sm:w-10/12 sm:mx-auto w-screen -ml-4"
      >
        {menu.map((link) => {
          const darkTheme =
            link.options.attributes.class.includes("theme-dark");

          // We assume that non translated pages has no alias
          if (link.url.match(isNotAlias) !== null) {
            return null;
          }

          return (
            <SwiperSlide key={link.title}>
              <Link href={link.url} prefetch={false} passHref>
                <a className="group flex flex-col items-center text-sm">
                  <span
                    className={classNames(
                      "inline-flex justify-center mb-4 p-4 rounded-full group-hover:bg-primary-dark group-hover:text-white transition-colors",
                      darkTheme
                        ? "bg-black text-primary"
                        : "bg-primary text-primary-darker"
                    )}
                  >
                    <HeroIcon
                      name={link.options.attributes["data-icon-name"]}
                      type={link.options.attributes["data-icon-type"]}
                      className="h-8 w-8"
                    />
                  </span>
                  <span className="underline underline-offset-4 decoration-transparent text-center transition-colors group-hover:decoration-primary mb-0.5">
                    {link.title}
                  </span>
                </a>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <SlideNextButton nextRef={nextRef} theme="rounded-outline" />
    </div>
  );
}
